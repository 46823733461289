import easterDate from './dates/easterDate'
import strtotime from './dates/strtotime'
import { bringMissingPartsPeriod } from '../constants/general'

/**
 * determine feast-day info for given date (timestamp)
 *
 * @param $tDay int|null des Tages, der auf einen Feiertag überprüft werden soll
 * @return array(
 *  'text' : '' Text
 *  'feast' : boolean True wenn Feiertag
 *  'arState' : '' Leerer Array oder Array mit Bundesländern für die der Feiertag gilt
 * )
 *
 *  BW = Baden-Württemberg
 *  BY = Bayern
 *  BE = Berlin
 *  BB = Brandenburg
 *  HB = Bremen
 *  HH = Hamburg
 *  HE = Hessen
 *  MV = Mecklenburg-Vorpommern
 *  NI = Niedersachsen
 *  NW = Nordrhein-Westfalen
 *  RP = Rheinland-Pfalz
 *  SL = Saarland
 *  SN = Sachsen
 *  ST = Sachsen-Anhalt
 *  SH = Schleswig-Holstein
 *  TH = Thüringen
 */
function getFeastInfo($tDay) {
    const $year = (new Date($tDay)).getFullYear()
    const $monthDay = (new Date($tDay)).getMonth() + '' + (new Date($tDay)).getDate()
    const $dayOfWeek = (new Date($tDay)).getDay()
    const $tEaster = easterDate($year)

    let $feast = '';
    let $arState = [];

    if ($monthDay === '0101') {
        $feast = ('Neujahr')
    } else if ($monthDay === '0501') {
        $feast = ('Erster Mai')
    } else if ($monthDay === '0106') {
        $feast = ('Heiligen Drei Könige');
        $arState = ['BW', 'BY', 'ST']
    } else if ($monthDay === monthDay(strtotime('-2 day', $tEaster))) {
        $feast = ('Karfreitag')
    } else if ($monthDay === monthDay(new Date($tEaster))) {
        $feast = ('Ostersonntag')
    } else if ($monthDay === monthDay(new Date(strtotime('+1 day', $tEaster)))) {
        $feast = ('Ostermontag')
        $arState = ['SN']
    } else if ($monthDay === monthDay(new Date(strtotime('+39 day', $tEaster)))) {
        $feast = ('Christi Himmelfahrt')
    } else if ($monthDay === monthDay(new Date(strtotime('+49 day', $tEaster)))) {
        $feast = ('Pfingstsonntag')
    } else if ($monthDay === monthDay(new Date(strtotime('+50 day', $tEaster)))) {
        $feast = ('Pfingstmontag')
    } else if ($monthDay === monthDay(new Date(strtotime('+60 day', $tEaster)))) {
        $feast = ('Fronleichnam')
        $arState = ['BW', 'BY', 'HE', 'NW', 'RP', 'SL', 'SN', 'TH'];
    } else if ($monthDay === '0815') {
        $feast = ('Maria Himmelfahrt')
        $arState = ['SL', 'BY']
    } else if ($monthDay === '1003') {
        $feast = ('Tag der deutschen Einheit')
    } else if ($monthDay === '1031') {
        $feast = ('Reformationstag')
        $arState = ['BB', 'MV', 'SN', 'ST', 'TH']
    } else if ($monthDay === '1101') {
        $feast = ('Allerheiligen')
        $arState = ['BW', 'BY', 'NW', 'RP', 'SL']
    } else if 
        ($monthDay === monthDay(new Date(
            strtotime(
                'last wednesday',
                new Date($year, 10, 23)
            )
        )))
    {
        $feast = ('Buß- und Bettag')
        $arState = ['SN']
    } else if ($monthDay === '1224') {
        $feast = ('Heiliger Abend')
    } else if ($monthDay === '1225') {
        $feast = ('1. Weihnachtsfeiertag')
    } else if ($monthDay === '1226') {
        $feast = ('2. Weihnachtsfeiertag')
    } else if ($monthDay === '1231') {
        $feast = ('Silvester (Bankfeiertag)')
    } else if ($monthDay === '0803') {
        $feast = ('Internationaler Frauentag')
        $arState = ['BE'];
    }

    let $text = '';
    if ($dayOfWeek === 6) {
        $text = 'Samstag';
    } else if ($dayOfWeek === 0) {
        $text = 'Sonntag';
    }

    let $feastDays = {}
    let $arStateMapping = {}
    if ($arState.length > 0) {
        $arStateMapping = {
            'BW' : 'Baden-Württemberg',
            'BB' : 'Brandenburg',
            'BY' : 'Bayern',
            'BE' : 'Berlin',
            'HB' : 'Bremen',
            'HH' : 'Hamburg',
            'HE' : 'Hessen' ,
            'MV' : 'Mecklenburg-Vorpommern',
            'NI' : 'Niedersachsen',
            'NW' : 'Nordrhein-Westfalen',
            'RP' : 'Rheinland-Pfalz',
            'SL' : 'Saarland',
            'SN' : 'Sachsen',
            'ST' : 'Sachsen-Anhalt',
            'SH' : 'Schleswig-Holstein',
            'TH' : 'Thüringen'
        };

        // foreach ($arState as $k => $v) {
        //     $arState[$k] = $arStateMapping[$v];
        // }
        $feastDays = $arState.map(feastDay => $arStateMapping[feastDay])
    }

    if ($feast === '' && $text === '') {
        $text = 'Arbeitstag';
    } else if ($feast !== '' && $text !== '') {
        $text += ' (' + $feast + ', ' + $feastDays.join(', ') + ')';
    } else if ($feast !== '') {
        $text = $feast + $arState.join(', ')
    }

    return {
        'text' : $text,
        'feast' : ($feast !== ''),
        'arState' : $arState
    };
}

function monthDay($tDay) {
    return (new Date($tDay)).getMonth() + '' + (new Date($tDay)).getDate()
}

/**
 * determine next non feast-day from given date
 *
 * @param int|null $tDay
 *
 * @return int|null
 */
function findNextWorkDay($tDay)
{
    const $fDay = getFeastInfo($tDay);
    if (($fDay['feast'] === true) || isWeekend($tDay)) {
        return findNextWorkDay($tDay + (24 * 60 * 60 * 1000));
    }
    return $tDay;
}

/**
 * determine last feast-day up to given date
 *
 * @param int|null $tDay
 *
 * @return int|null
 */
function findLastWorkDay($tDay)
{
    const $fDay = getFeastInfo($tDay);
    if (($fDay['feast'] === true) || isWeekend($tDay)) {
        return findLastWorkDay($tDay - (24 * 60 * 60 * 1000));
    }
    return $tDay;
}

/**
 * determine last feast-day up to given date
 *
 * @param int|null $tDay
 *
 * @return int|null
 */
function findLastFeastDay($tDay)
{
    const $fDay = getFeastInfo($tDay);
    if ($fDay['feast'] !== true) {
        return findLastFeastDay($tDay - (24 * 60 * 60 * 1000));
    }
    return $tDay;
}

/**
 * determine given day is a weekend day
 *
 * @param int|null $tDay
 *
 * @return bool
 */
function isWeekend($tDay)
{
    const dayOfWeek = (new Date($tDay)).getDay()
    return [6, 0].includes(dayOfWeek);
}


function getBringMissingPartsDate() {
    const today = new Date()
    let we_offset = 0
    if (today.getDay() >= 4) {
        we_offset = (7 - today.getDay()) * 24 * 60 * 60 * 1000
    }
    const estimatedDate = today.getTime() + we_offset + bringMissingPartsPeriod
    return (new Date(findNextWorkDay(estimatedDate))).toLocaleDateString('de-DE');
}

export {
    getFeastInfo,
    findLastFeastDay,
    findLastWorkDay,
    findNextWorkDay,
    isWeekend,
    strtotime,
    easterDate,
    getBringMissingPartsDate
}