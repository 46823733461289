/**
 * calcuatate `easter date` for given year
 * (!): Gregorian dates are fine, but only works
 *      for Julian dates between 1900-2100
 *
 * @param int $year
 *
 * @return false|int
 * @see https://stackoverflow.com/a/3589696
 */
export default function easterDate(y) {

    // do something crazy.
    const k = (y%19*19+15)%30;

    // do some more crazy...
    const e = (y%4*2+y%7*4-k+34)%7+k+127;

    // estimate the month. p.s. The "~~" is like Math.floor
    let m = ~~(e/31);

    // e % 31 => get the day
    let d=e%31;
    if (m>4){
        d += 1;
    }
    if (y > 2099){
        d += 1;
    }

    // if d is less than 30 days add 1
    if (d<30){
        d += 1;
    }
    // otherwise, change month to May
    // and adjusts the days to match up with May.
    // e.g., 32nd of April is 2nd of May
    else {
        m += 1;
        d = m - 34 + d;
    }

    return (new Date(y, m-1, d)).getTime()
}